const state = {
    authInfo: {
        isLogin: false,
        userInfo: {},
    }
}

const getters = {
    isLogin: (st) => () => st.authInfo.isLogin,
    getUsername: (st) => () => {
        if (st.authInfo.isLogin) {
            if (st.authInfo.userInfo.username){
                return st.authInfo.userInfo.username
            }else{
                return "<!-- Unknown User -->"
            }
        }else{
            return "Not Logged In"
        }
    },
}

const types = {
    isLogin : "isLogin",
    setLoginStatus: "setLoginStatus",
    setLogout: "setLogout",
    getUsername: "getUsername",
}

const mutations = {
    [types.setLoginStatus](st, loginInfo){
        st.authInfo.isLogin = true
        st.authInfo.userInfo = loginInfo
    },
    [types.setLogout](st){
        st.authInfo.isLogin = false
        st.authInfo.userInfo = {}
    },
}

const actions = {
    setLoginStatus({commit}, loginInfo){
        commit(types.setLoginStatus, loginInfo)
    },
    setLogout({commit}){
        commit(types.setLogout)
    },
}

export default {
    state,
    getters,
    types,
    mutations,
    actions
}