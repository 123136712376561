<template>
  <v-app>
    <v-app-bar
      app
      :color="staticConfig.themeConfig.topBarColor"
      fixed
      clipped-left
      dark
    >
      <v-app-bar-nav-icon @click.stop=" $store.commit('toggleDrawer') " />
      <v-toolbar-title>
        {{ staticConfig.title }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <login-view v-if="!isLogin()" />
      <admin-view v-if="isLogin()" />
    </v-main>
    <v-footer app>
      <p>Copyright &copy; {{ thisYear }} ZRY. All rights reserved.</p>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import LoginView from "@/views/login.vue";
import AdminView from "@/views/admin.vue";

export default {
  name: "App",

  components: {
    LoginView,
    AdminView,
  },

  mounted() {
    document.title = this.staticConfig.title
  },

  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters(["isLogin"]),
    thisYear() {
      var now = new Date()
      now.setTime(Date.now())
      return now.getFullYear()
    },
  },
};
</script>
