const state = {
    system: {
        isDrawerOn: true,
    },
    basic: {
        free_sign: { 
            jsonData: {
                rdg_api: false,
                name: "test-user",
                rdg_acl: {

                },
                rdg_endpoint: "",
            },
            ttl: 1800 * 1000,
        }
    }
}

const getters = {
    isDrawerOn: (st) => () => st.system.isDrawerOn,
    getBasicFreeSignJsonData: (st) => () => st.basic.free_sign.jsonData,
    getBasicFreeSignTTL: (st) => () => st.basic.free_sign.ttl,
}

const types = {
    isDrawerOn : "isDrawerOn",
    toggleDrawer: "toggleDrawer",
    setDrawer: "setDrawer",
    getBasicFreeSignJsonData: "getBasicFreeSignJsonData",
    getBasicFreeSignTTL: "getBasicFreeSignTTL",
    setBasicFreeSignJsonData: "setBasicFreeSignJsonData",
    setBasicFreeSignTTL: "setBasicFreeSignTTL",
}

const mutations = {
    [types.toggleDrawer](st){
        st.system.isDrawerOn = ! st.system.isDrawerOn
    },
    [types.setDrawer](st, val) {
        st.system.isDrawerOn = val
    },
    [types.setBasicFreeSignJsonData](st, jval) {
        st.basic.free_sign.jsonData = jval
    },
    [types.setBasicFreeSignTTL](st, val){
        st.basic.free_sign.ttl = val
    }
}

const actions = {

}

export default {
    state,
    getters,
    types,
    mutations,
    actions
}