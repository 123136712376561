<template>
  <div>
    <v-navigation-drawer
      app
      clipped
      v-model="isDrawerOn"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ getUsername() }}</v-list-item-title>
            <v-list-item-subtitle><a @click="doLogout">登出</a></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in staticConfig.navList"
          :key="item.name"
          :to="{name: item.name}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "admin-view",
  computed: {
    ...mapGetters({
      getUsername: "getUsername",
    }),
    isDrawerOn: {
      get() {
        return this.$store.getters.isDrawerOn();
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      },
    },
  },
  methods: {
    doLogout() {
      this.zllauth.Logout();
    },
  },
};
</script>