<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            :color="staticConfig.themeConfig.loginTitleBarBgColor"
            dark
          >
            <v-spacer
              style="text-align: center;font-size: 18pt;"
              :color="staticConfig.themeConfig.loginTitleBarFrColor"
            >系统登录</v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                placeholder="请输入用户名"
                hint="请输入用户名"
                v-model="username"
                label="用户名"
                type="text"
                clearable
              />
              <v-text-field
                label="密码"
                hint="请输入密码"
                min="8"
                placeholder="请输入密码"
                :append-icon="dispPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="() => (dispPassword = !dispPassword)"
                v-model="password"
                class="input-group--focused"
                clearable
                :type="dispPassword ? 'text' : 'password'"
              />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="doLogin"
                  dark
                  :color="staticConfig.themeConfig.basePrimaryColor"
                >登录</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialogWait"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        :color="staticConfig.themeConfig.waitDialogBgColor"
        dark
      >
        <v-card-text>
          请稍后......
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogInfo"
      hide-overlay
      width="300"
    >
      <v-alert
        tile
        :type="typeOfDialogInfo"
      >
        {{ textOfDialogInfo }}
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "login-view",
  data: () => ({
    username: "",
    password: "",
    dispPassword: false,
    dialogWait: false,
    dialogInfo: false,
    textOfDialogInfo: "",
    typeOfDialogInfo: "",
  }),
  methods: {
    showDialog(type, text) {
      this.typeOfDialogInfo = type;
      this.textOfDialogInfo = text;
      this.dialogInfo = true;
    },
    doLogin() {
      this.dialogWait = true;
      var localUsernameCache = this.username;
      var loginData = {
        mode: "admin",
        username: this.username,
        password: this.password,
      };
      this.zllauth
        .LoginWithAutoInitSecure(loginData, true)
        .then(
          () => {
            this.dialogWait = false;
            this.$store.commit("setLoginStatus", {
              username: localUsernameCache,
            });
            window.sessionStorage.setItem("auth_username", localUsernameCache);
          },
          (err) => {
            this.dialogWait = false;
            this.showDialog("error", "登录失败：" + err.emsg.zh_CN);
            console.error(err);
          }
        )
        .catch((err) => {
          this.dialogWait = false;
          this.showDialog(
            "error",
            "登录失败：内部错误（若您是专业人士，需要了解错误细节，详见浏览器脚本控制台）"
          );
          console.error(err);
        });
    },
  },
};
</script>