import Vue from 'vue'
import Vuex from 'vuex'

import Auth from "./modules/auth"
import UIState from "./modules/ui_state"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth, UIState,
  }
})
