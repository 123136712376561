import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import StaticConfig from "./config/staticConfig"
import zllauth1 from "zllauth1-frontend"
import axios from "axios"
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

Vue.prototype.staticConfig = StaticConfig
Vue.prototype.zllauth = new zllauth1.ZLLAuth1Frontend("/api/zllauth/")
Vue.prototype.zllauth.UsePersistedStorage(() => {
  return window.sessionStorage.getItem("zllauth_loginInfo")
}, (dstr) => {
  window.sessionStorage.setItem("zllauth_loginInfo", dstr)
}, () => {
  window.sessionStorage.removeItem("zllauth_loginInfo")
})
Vue.prototype.axios = axios

Vue.use(VueClipboard)

window.VueInst = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

window.VueInst.zllauth.SetLogoutCallback(() => {
  window.VueInst.$store.commit("setLogout")
  window.sessionStorage.removeItem("auth_username")
})

let jwt = window.VueInst.zllauth.GetJwt()
if (jwt) {
  if (jwt.success) {
    var uname = window.sessionStorage.getItem("auth_username")
    window.VueInst.$store.commit("setLoginStatus", {
      username: uname,
    })
  }
}
