import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
    path: '/',
    name: 'home',
    component: () => import('../views/subpages/Home.vue')
  },
   {
    path: '/basic/free/sign',
    name: 'basic-free-sign',
    component: () => import('../views/subpages/basic/free_sign.vue')
  },
   {
    path: '/basic/gordg/endpoints',
    name: 'basic-endpoints',
    component: () => import('../views/subpages/basic/get_endpoints.vue')
  },
   {
    path: '/basic/edge/sign',
    name: 'basic-edge-sign',
    component: () => import('../views/subpages/basic/edge_sign.vue')
  },
   {
    path: '/basic/client/sign',
    name: 'basic-client-sign',
    component: () => import('../views/subpages/basic/client_sign.vue')
  },
/*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  } */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
