export default {
    title: "ZRY GoRDG Token简单签名中心",
    themeConfig: {
        basePrimaryColor: "#4F8DB3",
        topBarColor: "#4F8DB3",
        loginTitleBarBgColor: "#4F8DB3",
        loginTitleBarFrColor: "#FFE0B2",
        waitDialogBgColor: "#039BE5",
    },
    navList: [
        {
            name: "basic-endpoints",
            title: "Endpoints列表",
            icon: "mdi-server-network"
        },
        {
            name: "basic-free-sign",
            title: "自由签",
            icon: "mdi-cloud-braces"
        },
        {
            name: "basic-edge-sign",
            title: "裸金属边缘签发器",
            icon: "mdi-server-plus"
        },
        {
            name: "basic-client-sign",
            title: "裸金属客户端签发器",
            icon: "mdi-console-network"
        },
    ],
}